'use client'

import {
  ArrowBottomRightIcon,
  ArrowDownIcon,
  AvatarIcon,
  ChatBubbleIcon,
  EnterFullScreenIcon,
  InfoCircledIcon,
  MinusIcon,
  ReloadIcon,
} from '@radix-ui/react-icons'
import type { SendChatbotMessageWebsocketData } from 'functions/public-chat/send-chat-message'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { ReadyState } from 'react-use-websocket'
import { css, cva } from 'styled-system/css'

import { AiModel, RoleType } from '@models/types'

import { Avatar } from '../Avatar'
import { Box } from '../Box'
import { Button } from '../Button'
import { CustomFlex } from '../CustomFlex'
import { SmartToyIcon } from '../CustomIcons/SmartToyIcon'
import { DropdownMenu, DropdownMenuProps } from '../Dropdowns/DropdownMenu'
import { Icon } from '../Icon'
import { ShoImage } from '../ShoImage'
import { Tag } from '../Tag'
import { Text } from '../Text'
import { Textfield } from '../Textfield'
import { Tooltip } from '../Tooltip'
import { ChatHistoryModal } from './ChatHistoryModal'
import { ConversationMessage } from './ConversationMessage'

export const REACT_ICON_SIZE = '18'

type FormInput = {
  message: string
}

let _prevScrollTop = 0
let _prevWidth = 0

export type ChatbotProps = {
  handleModelTypeChange: (modelType: AiModel) => void
  handleSendMessage: (value: string) => void
  handlePersonaChange: (persona: string) => void
  messages: {
    id: string
    role: RoleType
    content: string
    createdAt: string
    type?: 'message' | 'error'
  }[]
  modelType: AiModel
  personas: DropdownMenuProps['options']
  personaInitiallySelected?: DropdownMenuProps['initiallySelectedOption']
  userFirstName?: string
  userLastName?: string
  openByDefault?: boolean
  onResetChatbot: () => void
  chatHistory?: {
    id: string
    title: string
    updatedAt: string
    messages?: {
      id: string
      role: RoleType
      content: string
      createdAt: string
    }[]
  }[]
  onGetchatConversationsTitleList?: () => void
  onGetConversationById?: (conversationId: string) => void
  onChangeConversationId?: (conversationId: string) => void
  onUpdateConversationTitle?: (conversationId: string, title: string) => void
  onDeleteConversation?: (conversationId: string) => void
  isLoadingConversationsTitleList?: boolean
  isLoadingConversation?: boolean
  mode?: 'active' | 'under_paywall'
  chatbotProfileImage?: {
    src: string
    width: number
    height: number
  }
  readyState: ReadyState
  showBubbles?: boolean
  prompts?: { label: string; message: string }[]
  aiProcessingStage?: {
    stage: SendChatbotMessageWebsocketData['type']
    totalSteps?: number
    currentStep?: number
  }
  chatbotMinimizedDisplayName?: string
}

const CHATBOT_OPEN_STATE = 'chatbotOpenState'

export const Chatbot: FC<ChatbotProps> = props => {
  const {
    handleSendMessage,
    handlePersonaChange,
    personaInitiallySelected,
    messages,
    personas,
    // modelType,
    // handleModelTypeChange,
    userFirstName,
    onResetChatbot,
    userLastName,
    openByDefault = true,
    mode = 'active',
    chatbotProfileImage,
    readyState,
    showBubbles = false,
    prompts,
    aiProcessingStage,
    chatbotMinimizedDisplayName = 'Newsroom AI',
  } = props

  const [imageTooltextIsVisible, setImageTooltextIsVisible] = useState(true)
  const [isOpen, setIsOpen] = useState(() => {
    if (typeof window !== 'undefined') {
      const chatbotOpenState = localStorage.getItem(CHATBOT_OPEN_STATE)
      if (chatbotOpenState === null) {
        localStorage.setItem(CHATBOT_OPEN_STATE, openByDefault ? 'true' : 'false')
        return openByDefault
      }
      return chatbotOpenState === 'true' ? true : false
    } else {
      openByDefault
    }
  })

  useEffect(() => {
    // Hide the image tooltext after 10 seconds
    const timeout = setTimeout(() => {
      setImageTooltextIsVisible(false)
    }, 10000)
    return () => clearTimeout(timeout)
  }, [])

  const [isFullScreen, setIsFullScreen] = useState(false)
  const [rerender, setRerender] = useState(0)
  const [showButtonScrollToBottomOfChat, setShowButtonScrollToBottomOfChat] = useState(false)
  const [lockScrollToBottom, setLockScrollToBottom] = useState(true)
  const [scrollUpCount, setScrollUpCount] = useState(0)
  const pathname = usePathname()
  const params = useSearchParams()

  const isCmsPreview = !!params.get('previewKey')

  useEffect(() => {
    // When click on link to another page and chatbot full screen, reduce chatbot
    // Otherwise the user will not realize he/she is on another page
    setIsFullScreen(false)
  }, [pathname])

  useEffect(() => {
    // Refresh chatbot every minute so that time is updated
    const interval = setInterval(() => setRerender(prev => prev + 1), 1000 * 60)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { isDirty, dirtyFields, defaultValues, errors },
  } = useForm<FormInput>({ defaultValues: { message: '' } })

  const onToggleChatbot = useCallback(() => {
    setImageTooltextIsVisible(false)
    setIsOpen(prev => {
      if (prev) {
        setIsFullScreen(false)
      }
      if (typeof window !== 'undefined') {
        localStorage.setItem(CHATBOT_OPEN_STATE, !prev ? 'true' : 'false')
      }
      return !prev
    })
  }, [])

  const onToggleFullScreen = useCallback(() => {
    setIsFullScreen(prev => !prev)
  }, [])

  const handleScrollToBottomOfChat = () => {
    const el = document.getElementById('message-container')
    if (el) {
      el.scrollTop = el.scrollHeight - el.clientHeight
    }
  }

  const handleMessageWrapperScroll = useCallback(e => {
    // determine if scroll was going up or down: https://stackoverflow.com/questions/31223341/detecting-scroll-direction
    if (_prevWidth === 0) _prevWidth = e.target.clientWidth

    if (e.target.scrollTop > _prevScrollTop) {
      // scroll down
    } else if (e.target.scrollTop < _prevScrollTop) {
      // scroll up
      if (e.target.clientWidth >= _prevWidth) {
        // Adding this line to fix a bug. Basically, since the chatbot expands in width, the scrollTop will be smaller, automatically trigguering the upscroll code. This line prevents that from happening.
        setScrollUpCount(prev => prev + 1)
      }
    } else {
      _prevScrollTop = e.target.scrollTop <= 0 ? 0 : e.target.scrollTop
    }
    _prevScrollTop = e.target.scrollTop
    _prevWidth = e.target.clientWidth
  }, [])

  const onSubmit = useCallback(
    (form: FormInput) => {
      handleSendMessage(form.message)
      reset()
      const el = document.getElementById('message-container')
      setLockScrollToBottom(true)
    },
    [handleSendMessage, reset],
  )

  const handleShowBtnScrollToBottomOfChat = useCallback(() => {
    const el = document.getElementById('message-container')
    const chatbotPrompts = document.getElementById('chatbot-prompts')
    const chatbotPromptsHeight = chatbotPrompts?.clientHeight ? chatbotPrompts.clientHeight + 40 : 0
    if (el) {
      if (Math.ceil(el.scrollTop + el.clientHeight + chatbotPromptsHeight) < el.scrollHeight - 40) {
        setShowButtonScrollToBottomOfChat(true)
      } else {
        setShowButtonScrollToBottomOfChat(false)
      }
    }
  }, [])

  useEffect(() => {
    // Adding this useEffect and scrollUpCount effect to fix a bug.
    // Basically, sometimes the chatbot height shrinks because as markdown is rendered and converted to html, some elements change and the heigt thus changes as well.
    // So we need to prevent the upscroll code from being triggered when the height shrinks.
    if (scrollUpCount > 3) {
      setLockScrollToBottom(false)
      setScrollUpCount(0)
    }
  }, [scrollUpCount])

  useEffect(() => {
    if (lockScrollToBottom) {
      handleScrollToBottomOfChat()
    }
  }, [lockScrollToBottom, messages])

  useEffect(() => {
    // add an event listener to show ScrollToBottomButton when user is not at the bottom of the chat
    const el = document.getElementById('message-container')
    if (el) {
      el.addEventListener('scroll', handleShowBtnScrollToBottomOfChat)
    }
    return () => {
      if (el) {
        el.removeEventListener('scroll', handleShowBtnScrollToBottomOfChat)
      }
    }
  }, [handleShowBtnScrollToBottomOfChat])
  // only show ScrollToBottomButton if user is not at the bottom of the chat
  // const showButtonScrollToBottomOfChat = useRef(false)

  useEffect(() => {
    // show ScrollToBottomButton if user is not at the bottom of the chat
    handleShowBtnScrollToBottomOfChat()
  }, [handleShowBtnScrollToBottomOfChat, messages, prompts])

  const readyStateText = useMemo(() => {
    switch (readyState) {
      case 0:
        return 'Connecting...'
      case 1:
        return ''
      case 2:
        return 'Closing...'
      case 3:
        return 'Closed'
      default:
        console.warn('Unknown readyState', readyState)
    }
  }, [readyState])

  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  if (isCmsPreview) return null

  return (
    <div id="chatbot">
      <div className={chatbotContainer({ isOpen, isFullScreen })}>
        <div>
          <CustomFlex
            align="center"
            gap={'4'}
            justify="between"
            className={topHeaderContainer({
              isFullScreen,
            })}>
            <CustomFlex align="center" justify="end">
              {readyState !== 1 && mode === 'active' && (
                <Text variant="overline" css={warningText}>
                  {readyStateText}
                </Text>
              )}
              {!!personas?.length && personas.length > 1 && personaInitiallySelected !== null && (
                <DropdownMenu
                  contentMenuCss={css.raw({
                    backgroundColor: '$gs3',
                    zIndex: '[1 !important]',
                  })}
                  options={personas}
                  textVariant="body2"
                  initiallySelectedOption={personaInitiallySelected}
                  onSelectMenuItem={persona => {
                    handlePersonaChange(persona.key)
                  }}
                  color="grayscale">
                  <Tooltip content="Available personas" useDivElementForTrigger={true} ariaLabel="Available personas">
                    <Icon css={headerIconStyledWithHover} size={REACT_ICON_SIZE} reactIcon={<AvatarIcon />} />
                  </Tooltip>
                </DropdownMenu>
              )}
              <Tooltip
                content="Caution: This feature is under development and may yield errors. It improves with use. Privacy: Your conversations are private. We don't save sensitive data, but please avoid sharing confidential details like SSN or bank accounts."
                maxWidth="450px"
                isOpen={isTooltipOpen}
                ariaLabel="Chatbot Information">
                <Icon
                  css={headerIconStyledWithHover}
                  size={REACT_ICON_SIZE}
                  reactIcon={<InfoCircledIcon />}
                  onClick={e => {
                    setIsTooltipOpen(prev => !prev)
                  }}
                />
              </Tooltip>

              {mode !== 'under_paywall' && (
                <div>
                  <ChatHistoryModal {...props} isFullScreen={isFullScreen} />
                </div>
              )}

              {mode !== 'under_paywall' && (
                <Tooltip content="Start a new conversation" ariaLabel="New Conversation">
                  <Icon
                    css={headerIconStyledWithHover}
                    size={REACT_ICON_SIZE}
                    reactIcon={<ReloadIcon />}
                    onClick={onResetChatbot}
                  />
                </Tooltip>
              )}
              <Tooltip
                content={isFullScreen ? 'Minimize' : 'Full screen'}
                ariaLabel={isFullScreen ? 'Minimize' : 'Full Screen'}>
                <Icon
                  css={headerIconStyledWithHover}
                  size={REACT_ICON_SIZE}
                  reactIcon={isFullScreen ? <MinusIcon /> : <EnterFullScreenIcon />}
                  onClick={onToggleFullScreen}
                />
              </Tooltip>
              <Tooltip content="Hide chat" ariaLabel="Hide chat">
                <Icon
                  css={headerIconStyledWithHover}
                  size={REACT_ICON_SIZE}
                  reactIcon={<ArrowBottomRightIcon />}
                  onClick={onToggleChatbot}
                />
              </Tooltip>
            </CustomFlex>
          </CustomFlex>
          {/* <CustomFlex
            css={{ backgroundColor: '$gs3', padding: '$1', borderBottom: '1px solid $gs6' }}
            justify="center"
            gap="2">
            <Switch
              checked={modelType === 'GPT4'}
              onChange={() => handleModelTypeChange(modelType === 'GPT4' ? 'GPT3' : 'GPT4')}
              leftText="Faster AI Model"
              rightText="Smarter AI Model"
              textVariant="caption"
              disabled={mode === 'under_paywall'}
              css={{ justifyContent: 'center' }}
            />
          </CustomFlex> */}
        </div>
        <Box css={css.raw({ position: 'relative' })}>
          <div
            className={messageWrapper({
              under_paywall: mode === 'under_paywall',
              isFullScreen,
            })}
            id="message-container"
            onScroll={handleMessageWrapperScroll}>
            {mode === 'active' && (
              <>
                {messages.map(m => {
                  const role = m.role
                  let time = ''
                  // if timestamp is less than 1 minute ago
                  if (Date.now() - new Date(m.createdAt).getTime() < 1000 * 60) {
                    time = 'Just now'
                  } // timestamp is less than 1 hour ago
                  else if (Date.now() - new Date(m.createdAt).getTime() < 1000 * 60 * 60) {
                    time = `${Math.floor((Date.now() - new Date(m.createdAt).getTime()) / (1000 * 60))} minutes ago`
                  } // timestamp is less than 1 day ago
                  else if (Date.now() - new Date(m.createdAt).getTime() < 1000 * 60 * 60 * 24) {
                    time = `${Math.floor((Date.now() - new Date(m.createdAt).getTime()) / (1000 * 60 * 60))} hours ago`
                  } else {
                    time = `${Math.floor(
                      (Date.now() - new Date(m.createdAt).getTime()) / (1000 * 60 * 60 * 24),
                    )} days ago`
                  }
                  return (
                    <ConversationMessage
                      isVisible={mode === 'active'}
                      key={m.id}
                      message={m}
                      role={role}
                      isFullScreen={isFullScreen}
                      chatbotProfileImage={chatbotProfileImage}
                      userFirstName={userFirstName}
                      userLastName={userLastName}
                      time={time}
                    />
                  )
                })}
                {showBubbles && (
                  <CustomFlex align="end" gap="3" justify="start" css={{ paddingLeft: '$2', paddingRight: '$2' }}>
                    {chatbotProfileImage ? (
                      <ShoImage
                        src={chatbotProfileImage.src}
                        width={chatbotProfileImage.width}
                        height={chatbotProfileImage.height}
                        alt="AI Chatbot Profile Image"
                        sizes="38px"
                        imageCss={css.raw({
                          width: '[38px]',
                          height: '[38px]',
                          objectFit: 'contain',
                          borderRadius: '$round',
                        })}
                      />
                    ) : (
                      <Avatar customReactIcon={<SmartToyIcon />} />
                    )}
                    <div
                      className={messageContainer({
                        role: 'assistant',
                        isFullScreen,
                        loading: true,
                      })}>
                      <CustomFlex
                        css={css.raw({
                          // width: '[60px]',
                          // height: '[20px]',
                          '& div': { ml: '[20px]' },
                        })}
                        align="center">
                        <Text variant="body2" css={aIProcessingStageTextStyle}>
                          {getAiProcessingStageText(aiProcessingStage)}
                        </Text>
                      </CustomFlex>
                    </div>
                  </CustomFlex>
                )}
                {!!(messages.length && prompts?.length) && (
                  <CustomFlex gap={'2'} wrap="wrap" id="chatbot-prompts">
                    {prompts?.map(({ label, message }) => (
                      <Tag
                        type="default"
                        selectable={true}
                        key={label}
                        id={label}
                        label={label}
                        onClick={() => handleSendMessage(message)}
                        css={css.raw({ cursor: 'pointer' })}
                      />
                    ))}
                  </CustomFlex>
                )}
              </>
            )}
            {mode === 'under_paywall' && (
              <CustomFlex align="center" justify="center" direction="column">
                <Text
                  variant="body2"
                  css={css.raw({
                    '& a': {
                      color: '$lin',
                      _hover: {
                        transitionProperty: 'color',
                        transitionDuration: '$normal',
                        transitionTimingFunction: 'in-out',
                        opacity: '0.8',
                      },
                    },
                  })}>
                  Please{' '}
                  <Link href="/sign-in" prefetch={false}>
                    sign in
                  </Link>{' '}
                  to access the {chatbotMinimizedDisplayName} chatbot
                </Text>
              </CustomFlex>
            )}
          </div>
          {showButtonScrollToBottomOfChat && (
            <Icon
              reactIcon={<ArrowDownIcon />}
              onClick={() => setLockScrollToBottom(true)}
              css={scrollToBottomButton}
            />
          )}
        </Box>

        <form onSubmit={handleSubmit(onSubmit)} className={chatbotFooter}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <Textfield
                {...field}
                inputVariant="body2"
                placeholder="Type your message here"
                fullWidth={true}
                autocomplete="off"
                disabled={mode === 'under_paywall'}
              />
            )}
          />
          <Button
            variant="primary"
            label="Send"
            buttonType="submit"
            disabled={mode === 'under_paywall' || readyState !== 1}
          />
        </form>
      </div>
      <div
        className={imageToggleContainer({
          isHidden: isFullScreen || isOpen,
        })}
        onClick={onToggleChatbot}>
        <Text
          variant="body2"
          className={imageToggleText({
            visible: imageTooltextIsVisible,
          })}>
          {chatbotMinimizedDisplayName}
        </Text>
        <div className={imageContainer}>
          {chatbotProfileImage ? (
            <ShoImage
              src={chatbotProfileImage.src}
              width={chatbotProfileImage.width}
              height={chatbotProfileImage.height}
              alt="AI Chatbot Toggle"
              sizes="32px"
              imageCss={css.raw({
                width: '[48px]',
                height: '[48px]',
                objectFit: 'contain',
                borderRadius: '$round',
                cursor: 'pointer',
              })}
            />
          ) : (
            <Icon
              css={{
                width: '[48px]',
                height: '[48px]',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              reactIcon={<ChatBubbleIcon />}
              pointer={true}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const getAiProcessingStageText = (aiProcessingStage?: {
  stage: SendChatbotMessageWebsocketData['type']
  totalSteps?: number
  currentStep?: number
}): string => {
  switch (aiProcessingStage?.stage) {
    case 'message':
      return 'Generating'
    case 'reranking':
      return aiProcessingStage?.currentStep && aiProcessingStage?.totalSteps
        ? `Analyzing sources ${aiProcessingStage.currentStep}/${aiProcessingStage.totalSteps}`
        : 'Analyzing sources'
    case 'queryAnalysis':
      return 'Analyzing query'
    case 'search':
      return 'Searching'
    case 'error':
      return 'Error'
    default:
      return 'Reasoning'
  }
}

const aIProcessingStageTextStyle = css.raw({
  color: '$b4',
  backgroundImage: 'linear-gradient(90deg, {colors.$gs5} 0%, {colors.$gs1} 50%, {colors.$gs5} 100%)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '30% 100%',
  backgroundClip: 'text' as any,
  animationName: 'shimmer',
  animationDuration: '3s',
  animationDelay: '0s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  display: 'inline-block',
})

const warningText = css.raw({
  color: '$warText',
  backgroundColor: '$war',
  py: '$1',
  px: '$2',
  borderRadius: '$3',
  // animation: `${showOn10s} 10s`,
})

const scrollToBottomButton = css.raw({
  position: 'absolute',
  bottom: '[20px]',
  right: '[50%]',
  transform: 'translateX(50%)',
  color: '$gs9',
  backgroundColor: '$gs1',
  borderRadius: '$round',
  padding: '$2',
  cursor: 'pointer',
  opacity: '0.9 !important',
  _hover: {
    transitionProperty: 'opacity',
    transitionDuration: '$normal',
    transitionTimingFunction: 'in-out',
  },
})

const topHeaderContainer = cva({
  base: {
    px: '$3',
    py: '$1',
    backgroundColor: '$gs12',
    color: '$gs1',
  },
  variants: {
    isFullScreen: {
      true: {},
      false: {
        borderTopRightRadius: '$3',
        borderTopLeftRadius: '$3',
      },
    },
  },
  defaultVariants: {
    isFullScreen: false,
  },
})

const chatbotFooter = css({
  display: 'flex',
  gap: '$2',
  padding: '$3',
  boxShadow: '[{colors.$b11} 0px -1px 10px 1px]',
})

export const messageContainer = cva({
  base: {
    display: 'inline-block',
    borderRadius: '$3',
    color: '$gs12',
    padding: '$2',
    txtStyle: 'body1',
    fontSize: '[1.6rem]',
  },
  variants: {
    role: {
      user: {
        alignSelf: 'flex-end',
        backgroundColor: '$gs4',
      },
      assistant: {
        alignSelf: 'flex-start',
        backgroundColor: '$gs3',
      },
    },
    isFullScreen: {
      true: {
        maxWidth: '[75vw]',
      },
      false: {
        maxWidth: '[min(75%, 385px)]',
      },
    },
    isErrorMessage: {
      true: {
        backgroundColor: '$war',
        color: '$warText',
      },
      false: {},
    },
    loading: {
      true: {
        backgroundColor: '$gs9',
      },
      false: {},
    },
  },
  defaultVariants: {
    role: 'assistant',
    isFullScreen: false,
    isErrorMessage: false,
    loading: false,
  },
})

const messageWrapper = cva({
  base: {
    overflowY: 'auto',
    overscrollBehavior: 'contain',
    px: '$3',
    py: '$5',
    boxSizing: 'border-box',
    display: 'flex',
    width: '[100%]',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    gap: '$10',
  },
  variants: {
    under_paywall: {
      true: {
        justifyContent: 'center',
      },
      false: {
        justifyContent: 'flex-start',
      },
    },
    isFullScreen: {
      true: {
        maxHeight: '[calc(100vh - 114px)]',
      },
      false: {
        maxHeight: '[50vh]',
      },
    },
  },
  defaultVariants: {
    isFullScreen: false,
  },
})

export const headerIconStyledWithHover = css.raw({
  color: '$gs1',
  padding: '$2',
  borderRadius: '$3',
  // fontSize: '[18px !important]',
  _hover: {
    opacity: 0.8,
    backgroundColor: '$gs11',
    transitionProperty: 'all',
    transitionDuration: '$normal',
    transitionTimingFunction: 'in-out',
  },
})

const chatbotContainer = cva({
  base: {
    position: 'fixed',
    zIndex: '[20]', // Don't set z-index beyond 20 as it will overlap with other elements on the page with fixed, rendering them unseable. Like for example BlockPubSearch/InstantSearchBlock.tsx, open the specific date range in mobile, and half of it will be hidden behind the chatbot.
    boxShadow: '$around',
    backgroundColor: '$gs1',
    borderRadius: '$3',
    flexFlow: 'column nowrap',
  },
  variants: {
    isOpen: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
    isFullScreen: {
      true: {
        inset: '$0',
        width: '[100%]',
        '& > *:nth-child(2)': {
          flex: '[1 1 0%]',
        },
      },
      false: {
        borderTopRightRadius: '$4', // add border radius to the top right and left of the chatbot in addition to the border radius set in child div because otherwise we have a very small shiny corner (bug). It's also set to $4 and not $3 on purpose, to fix the bug.
        borderTopLeftRadius: '$4',
        maxWidth: '[min(70vw, 600px)]',
        bottom: '$3',
        right: '$3',
        bp2: {
          right: '$5',
          bottom: '$5',
        },
        bp3: {
          right: '$8',
          bottom: '$8',
        },
      },
    },
  },
  defaultVariants: {
    isOpen: true,
    isFullScreen: false,
  },
})

const imageToggleContainer = cva({
  base: {
    cursor: 'pointer',
    backgroundColor: '$pri',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    bottom: '$3',
    right: '$3',
    // backgroundColor: '$pri',
    color: '$priText !important',
    borderRadius: '$round',
    boxShadow: '$around',
    border: 'none',
    _hover: {
      opacity: 0.8,
      transitionProperty: 'opacity',
      transitionDuration: '$normal',
      transitionTimingFunction: 'in-out',
      '& > .text': {
        // Target the text element
        maxWidth: '[220px]',
        opacity: 1,
        pl: '$4',
        pr: '$2',
        visibility: 'visible',
      },
    },
    bp2: {
      right: '$5',
      bottom: '$5',
    },
    bp3: {
      right: '$8',
      bottom: '$8',
    },
  },
  variants: {
    isHidden: {
      true: {
        display: 'none !important',
      },
      false: {
        zIndex: '[1000]',
      },
    },
  },
  defaultVariants: {
    isHidden: true,
  },
})

const imageContainer = css({
  maxHeight: '[48px]',
  maxWidth: '[48px]',
})

const imageToggleText = cva({
  base: {
    transitionProperty: 'all',
    transitionDuration: '1s',
    transitionTimingFunction: 'in-out',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  variants: {
    visible: {
      true: {
        maxWidth: '[220px]',
        opacity: 1,
        pl: '$4',
        pr: '$2',
      },
      false: {
        maxWidth: '0',
        opacity: 0,
        pl: '0',
        pr: '0',
      },
    },
  },
})
