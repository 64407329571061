import { getTimezoneOffset, toZonedTime } from 'date-fns-tz'

import { capitalizeFirstLetter } from './string-utils'

// https://stackoverflow.com/questions/13894632/get-time-difference-between-two-dates-in-seconds/13894670
// careful! might not work with typescript

const dateComparisonInSeconds = (startTime: string, endTime: string): number => {
  const t1 = new Date(startTime)
  const t2 = new Date(endTime)

  return (t2.getTime() - t1.getTime()) / 1000
}

export default dateComparisonInSeconds

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const convertAWSDateToHumanReadableDate = ({
  date,
  IANATimezone,
  shortFormat = false,
}: {
  date: string | Date | undefined
  IANATimezone?: string
  shortFormat?: boolean
}) => {
  if (!date) {
    return ''
  }
  const d = new Date(date)
  // short format: 12/31/2020, at 12:00
  // long format: Thur Dec 31 2020, at 12:00
  const dateAndTimeWithoutGMT = `${
    shortFormat ? new Intl.DateTimeFormat('en-US').format(d) : d.toDateString()
  }, at ${getHoursAndMinutesFromDate(d)}`

  if (IANATimezone) {
    const timezoneInGmt = IANATimezoneToGMT(IANATimezone)
    return `${dateAndTimeWithoutGMT} (${timezoneInGmt})`
  }
  return dateAndTimeWithoutGMT
}

export const getHoursAndMinutesFromDate = (date: Date = new Date()): string => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return `${hours}:${(minutes < 10 ? '0' : '') + minutes}`
}

export const abbreviateMonthName = (monthName: string): string => {
  switch (monthName.toLowerCase()) {
    case 'may':
      return 'May'
    case 'june':
      return 'June'
    case 'july':
      return 'July'
    default:
      return `${capitalizeFirstLetter(monthName.slice(0, 3))}.`
  }
}

export const isStoryArchived = (date?: string | number | null): boolean => {
  if (!date || date === '0' || date === 0) return false
  try {
    // if date is of type number, then it's a Unix timestamp
    // otherwise, it is a UTC string
    const archiveDate = typeof date === 'number' ? new Date(date * 1000) : new Date(date)
    const currentDate = new Date() // This is created
    return archiveDate <= currentDate
  } catch (error) {
    console.log('error in isStoryExpired: error, date, timeZone', error, date)
    return true // if there is an error, just return false
  }
}

export const isReleaseDateInFuture = (releaseDateInUTC: string): boolean => {
  try {
    const releaseDate = new Date(releaseDateInUTC)
    const currentDate = new Date() // This is created
    return releaseDate > currentDate
  } catch (error) {
    console.log('error in isReleaseDateInFuture: error, releaseDateInUTC, timeZone', error, releaseDateInUTC)
    return true // if there is an error, just return false
  }
}

export const IANATimezoneToGMT = (IANATimezone?: string): string => {
  if (!IANATimezone) {
    return ''
  }
  const resultInMs = getTimezoneOffset(IANATimezone)
  const { hours, minutes } = getHMSfromSeconds(resultInMs / 1000)
  const result = `GMT${hours > 0 ? '+' : '-'}${hours < 10 ? `0${Math.abs(hours)}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`
  return result
}

export const getHMSfromSeconds = (timeInSeconds: number): { hours: number; minutes: number; seconds: number } => {
  const hours = Math.floor(timeInSeconds / 3600)
  const minutes = Math.floor((timeInSeconds - hours * 3600) / 60)
  const seconds = timeInSeconds - hours * 3600 - minutes * 60
  return {
    hours,
    minutes,
    seconds,
  }
}

// This function changes one timestamp to another timestamp, using the timezone as delta.
// This comes in handy when for Datepicker for example, which either uses the system timezone or UTC, but not an alternative timezone
// So we want to convert the date so that the datepicker shows the correct date in time in an alternative timezone. Or convert back.
// For example:
// Timestamp is 2021-05-01T00:00:00.000Z
// We want to display this in Paris time, which is GMT+2
// So we want to display 2021-05-01T02:00:00.000Z (even though it is the incorrect time).
// But we'll then want to save it as 2021-05-01T00:00:00.000Z (which is the correct time
export const epochShiftToTimezone = (date: string | Date | undefined | null, timezone?: string): string => {
  if (!date) return ''
  if (!timezone) return date.toString()
  // Careful! This will convert the date to show the correct time but "(in the system time zone)"
  // https://www.npmjs.com/package/date-fns-tz#utctozonedtime
  const timeAsLocalTimezone = toZonedTime(date, timezone)
  const utcToCorrectEpochShift = new Date(timeAsLocalTimezone.toString().split('GMT')[0] + 'GMT-0000').toISOString()
  return utcToCorrectEpochShift
}

// Careful with this function!
// It displays the date and time in the corret timezone.
// However the GMT will be whatever the system timezone is
// So if want to convert to ISOString, you'll have to remove the GMT part
export const utcToChosenTimezone = (date: string | Date | undefined | null, timezone?: string): string => {
  if (!date) return ''
  if (!timezone) return date.toString()
  // Careful! This will convert the date to show the correct time but "(in the system time zone)"
  // https://www.npmjs.com/package/date-fns-tz#utctozonedtime
  const timeAsLocalTimezone = toZonedTime(date, timezone)
  return timeAsLocalTimezone.toString()
}

export const convertIsoDateToTimestamp = (isoDate: string): number => {
  const date = new Date(isoDate)
  return date.getTime()
}

export const convertToUTC = (dateString: string) => {
  // Ex: convert YYYY-MM-DD HH:MM to dateInUTC
  // '2024-08-21 15:28'
  // "2024-08-21T13:28:00.000Z"
  // Extract the date and time components
  const [datePart, timePart] = dateString.split(' ')
  const [year, month, day] = datePart.split('-').map(Number)
  const [hour, minute] = timePart.split(':').map(Number)

  // Create a Date object in UTC by specifying the time zone offset as zero
  const dateInUTC = new Date(Date.UTC(year, month - 1, day, hour, minute))

  return dateInUTC.toISOString()
}

// from 2023-09-25T05:00:00.000Z
// to "2023-09-25 05:00"
export const convertUTCTimezoneToDatepickerValueFormat = (date: string) => {
  const [datePart, timePart] = date.split('T')
  const [hours, minutes] = timePart.split(':')
  return `${datePart} ${hours}:${minutes}`
}
